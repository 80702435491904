.logo {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  transition: opacity .75s ease-in-out;
  &__square {
    background: #f26c4b;
    width: 11px;
    height: 11px;
    border-radius: 25%;
    margin-top: -2px;
    &--external {
      background: #fac950;
    }
  }
  &__text {
    padding-left: 3px;
    font-size: 16px;
    font-family: "Nunito Extra Bold", sans-serif;
  }
}
