.container {
  height: 100vh;
  overflow: scroll;
  transition: background .1s ease-in-out;
  &--light {
    background: $white;
    .card__color {
      box-shadow:  20px 20px 60px #d9d9d9,
      -20px -20px 60px #ffffff;
    }
    .logo__text {
      color: $primary-text;
    }
    .nav__link {
      color: $text;
      :visited {
        color: $primary-text;
      }
      &--active {
        color: $primary-text;
      }
    }
    .menu {
      &__separator {
        background: #d3d3d3;
      }
      &__button {
        background: #edeff1;
        &:hover {
          background: #e3e5e7;
        }
      }
    }
    .card {
      &__text {
        color: #383b3f;
      }
      &__gradient-text {
        color: #383b3f;
      }
    }
    .controls {
      &--button {
        color: #5d656f;
        box-shadow: 2px 2px 6px #ececec, -2px -2px 6px #fafafa;
      }
      &--generate {
        background: #d1d7e0;
        &:hover {
          background: #ccd1db;
        }
      }
      &--back {
        background: #eaedf3;
        &:hover {
          background: #e5e8ee;
        }
      }
      &--copy {
        background: #eaedf3;
        &:hover {
          background: #e5e8ee;
        }
      }
    }
  }
  &--dark {
    background: $dark;
    .card__color {
      box-shadow:  20px 20px 60px #353535,
      -20px -20px 60px #222222;
    }
    .logo__text {
      color: $white;
    }
    .nav__link {
      color: #a3a3a3;
      :visited {
        color: #d7dae0;
      }
      &--active {
        color: #d7dae0;
      }
    }

    .menu {
      &__separator {
        background: #333942;
      }
      &__button {
        background: #30343a;
        &:hover{
          background: #3a3e44;
        }
      }
    }
    .card {
      &__text {
        color: #fafafa;
      }
      &__gradient-text {
        color: #fafafa;
      }
    }
    .controls {
      &--button {
        color: #f5f5f5;
      }
      &--generate {
        background: #333942;
        &:hover {
          background: #383e47;
        }
      }
      &--back {
        background: #282d34;
        &:hover {
          background: #2d3239;
        }
      }
      &--copy {
        background: #282d34;
        &:hover {
          background: #2d3239;
        }
      }
    }
  }
}
